<template>
  <button v-if="isNavigation" :href="url" v-smooth-scroll class="text-dfink-white text-base py-3 px-8 border border-dfink-white rounded-full transform hover:text-dfink-background hover:bg-dfink-white transition duration-300 hover:scale-110">{{ text }}</button>
  <button v-else class="text-dfink-white text-base py-3 px-8 border border-dfink-white rounded-full transform hover:text-dfink-background hover:bg-dfink-white transition duration-300 hover:scale-110" @click="clickEvent()">{{ text }}</button>

</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Button',
  props: {
    text: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    isNavigation: {
      type: Boolean,
      required: true
    },
  },
  setup(props) {

    function clickEvent() {
      window.open(props.url, '_blank');
    }

    return {
      clickEvent
    }
  }
});
</script>

<style scoped>
</style>
