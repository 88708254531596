
import { defineComponent } from 'vue';
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'Contact',
  components: {
    Button
  },
  setup() {
    const copyrightYear = (new Date()).getFullYear();

    return {
      copyrightYear
    }
  }
});
