<template>
  <Navbar></Navbar>
  <router-view class="bg-dfink-background overflow-x-hidden" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Navbar from '@/components/Navbar.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'Home',
  components: {
    Navbar
  },
});
</script>

<style>
</style>
