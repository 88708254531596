<template>
  <nav class="fixed z-10 left-0 right-0">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 backdrop-filter backdrop-blur-sm" :class="{ 'md:bg-transparent bg-dfink-background': showMobileMenu}">
      <div class="flex items-center justify-between h-16">
        <div class="flex items-center">
          <div class="mr-8 flex items-center" :class="{'hidden' : showMobileMenu}">
            <a href="#hero" v-smooth-scroll class="text-dfink-white opacity-70 py-2 rounded-md text-base font-normal hover:opacity-100">DANIEL FINK</a>
          </div>
        </div>
        <div class="hidden md:block">
          <div class="ml-8 flex items-center">
            <a href="#about" v-smooth-scroll class="text-dfink-white opacity-70 pr-8 py-2 rounded-md text-base font-normal hover:opacity-100">ÜBER MICH</a>
            <a href="#portfolio" v-smooth-scroll class="text-dfink-white opacity-70 pr-8 py-2 rounded-md text-base font-normal hover:opacity-100">PORTFOLIO</a>
            <a href="#contact" v-smooth-scroll class="text-dfink-white opacity-70 py-2 rounded-md text-base font-normal hover:opacity-100">KONTAKT</a>
          </div>
        </div>
        <div class="-mr-2 flex md:hidden">
          <!-- Mobile menu button -->
          <button type="button" class="inline-flex items-center justify-center p-2 text-dfink-white focus:outline-none" aria-controls="mobile-menu" aria-expanded="false" @click="toggleMobileMenu()">
            <span class="sr-only">Open main menu</span>

            <svg class="block h-6 w-6" :class="{ 'hidden': showMobileMenu}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>

            <svg class="block h-6 w-6" :class="{ 'hidden': !showMobileMenu}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div class="md:hidden" id="mobile-menu" v-show="showMobileMenu">
      <div class="flex flex-col justify-evenly items-center bg-dfink-background min-h-screen">
        <a href="#hero" v-smooth-scroll class="text-dfink-white block text-4xl font-black -mt-8" @click="toggleMobileMenu()">HOME</a>
        <a href="#about" v-smooth-scroll class="text-dfink-white block text-4xl font-black" @click="toggleMobileMenu()">ÜBER MICH</a>
        <a href="#portfolio" v-smooth-scroll class="text-dfink-white block text-4xl font-black" @click="toggleMobileMenu()">PORTFOLIO</a>
        <a href="#contact" v-smooth-scroll class="text-dfink-white block text-4xl font-black pb-24" @click="toggleMobileMenu()">KONTAKT</a>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Navbar',

  setup() {
    const showMobileMenu = ref(false);

    function toggleMobileMenu() {
      showMobileMenu.value = !showMobileMenu.value;
    }

    return {
      showMobileMenu, toggleMobileMenu
    }
  }
});
</script>

<style scoped>
</style>
