<template>
  <div class="bg-dfink-background">
    <Hero></Hero>
    <About></About>
    <Portfolio></Portfolio>
    <Contact></Contact>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Hero from '@/components/sections/Hero.vue'; // @ is an alias to /src
import About from '@/components/sections/About.vue'; // @ is an alias to /src
import Portfolio from '@/components/sections/Portfolio.vue'; // @ is an alias to /src
import Contact from '@/components/sections/Contact.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'Home',
  components: {
    Hero,
    About,
    Portfolio,
    Contact
  },
});
</script>
