<template>
  <div class="w-full h-full flex flex-col justify-center">

    <!-- Desktop from 1024px-->
    <div class="relative h-72 xl:h-96 xl:top-96 -mt-72 xl:-mt-96 hidden lg:block opacity-0" v-scrollanimation="{beforeEnter: 'circle-before-enter', enter: 'fadeIn', once: true}">
      <div class="absolute h-32 w-32 right-2/4 lg:top-64 xl:-top-8 rounded-full opacity-75 bg-gradient-to-br" :class="bgGradient"></div>
      <div class="absolute h-48 w-48 -right-24 lg:-bottom-96 xl:-bottom-28 rounded-full opacity-50 bg-gradient-to-br" :class="bgGradient"></div>
    </div>

    <!-- Mobile and Tablet to 1024px -->
    <div class="absolute mt-96 h-96 w-full lg:hidden opacity-0" v-scrollanimation="{beforeEnter: 'circle-before-enter', enter: 'fadeIn', once: true}">
      <div class="absolute h-24 w-24 -left-8 -top-48 sm:-top-8 md:top-12 rounded-full opacity-75 bg-gradient-to-br" :class="bgGradient"></div>
      <div class="absolute h-32 w-32 -right-8 -bottom-16 sm:-bottom-18  md:-bottom-24 rounded-full opacity-75 bg-gradient-to-br" :class="bgGradient"></div>
    </div>

    <div class="rounded-3xl" v-scrollanimation="{beforeEnter: 'card-before-enter', enter: 'card-enter', leave: 'card-leave', once: true}">
      <div ref="cardRef" class="card-glass relative rounded-3xl backdrop-filter backdrop-blur-md">
        <!-- Content -->
        <div class="h-full flex flex-col lg:flex-row rounded-3xl">
          <div class="lg:w-1/3">
            <img class="object-cover w-full h-full rounded-t-3xl lg:rounded-r-none lg:rounded-bl-3xl" :src="require('@/assets/images/' + imageURL + '')" />
          </div>

          <div class=" lg:w-2/3 flex flex-col items-center p-6 md:pt-9 lg:p-12">
            <h3 class="text-dfink-white text-xl 2xl:text-2xl font-medium uppercase text-center">
              <slot name="cardTitle"></slot>
            </h3>
            <div class="text-dfink-white text-base 2xl:text-lg pt-6 md:pt-9">
              <slot name="cardDescription"></slot>
            </div>
            <slot></slot>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import VanillaTilt from 'vanilla-tilt';

export default defineComponent({
  name: 'PortfolioCard',
  props: {
    imageURL: {
      type: String,
      required: true
    },
    fromBgGradientColor: {
      type: String,
      required: false
    },
    viaBgGradientColor: {
      type: String,
      required: false
    },
    toBgGradientColor: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const cardRef = ref();
    const bgGradient = computed(() => {
      return "from-" + props.fromBgGradientColor + " via-" + props.viaBgGradientColor + " to-" + props.toBgGradientColor;
    });

    function is_touch_enabled() {
      return ('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0);
    }

    onMounted(() => {
      if (!is_touch_enabled()) {
        VanillaTilt.init(cardRef.value, {
          reverse: true,
          max: 10,
          glare: true,
          reset: true,
          "max-glare": 0.5
        });
      }
    })

    return {
      cardRef, bgGradient
    }
  }
});
</script>

<style scoped>
.card-glass {
  z-index: 1;
  box-shadow: 20px 20px 50px rgb(0,0,0,0.5);
  background: rgb(255,255,255,.05);
  border-top: 1px solid rgb(255,255,255,0.5);
  border-left: 1px solid rgb(255,255,255,0.5);
  border-bottom: 1px solid rgb(255,255,255,0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.card-before-enter {
  transition: all 1s;
}

.card-enter {
  opacity: 1;
  transform: scale(1);
}

.card-leave {
  opacity: 0;
  transform: scale(0.9);
}

.circle-before-enter {
  animation-duration: 2s;
  animation-delay: 1s;
  animation-fill-mode: both;
}
</style>
