
import { defineComponent } from 'vue';
import PortfolioCardR from '@/components/PortfolioCardR.vue'
import PortfolioCardL from '@/components/PortfolioCardL.vue'

export default defineComponent({
  name: 'Portfolio',
  components: {
    PortfolioCardR,
    PortfolioCardL
  },
  setup() {
    return {}
  }
});
