<template>
  <section id="portfolio" class="relative w-screen">

    <div class="min-h-screen max-w-7xl mx-auto px-4 sm:px-6 flex flex-col justify-start items-center">
      <div class="mt-24">
        <div v-scrollanimation="{enter: 'fadeInUp', leave: 'fadeOutUp', once: true}">
          <h2 class="text-dfink-white text-3xl font-black">PORTFOLIO</h2>
          <div class="border-t-2 border-solid mx-auto w-16 mt-4"></div>
        </div>
      </div>

      <PortfolioCardR :imageURL="'seat_5_medium.jpg'" :fromBgGradientColor="'red-100'" :viaBgGradientColor="'yellow-50'" :toBgGradientColor="'gray-800'" class="mt-24 3xl:mt-32">
        <template v-slot:cardTitle>PILOT BESTELLAPP FÜR SPEISEN UND GETRÄNKE IM ZUG</template>
        <template v-slot:cardDescription>
          <p>
            Bisher kennt man in der Schweiz vor allem das SBB Restaurant, um im Zug Speisen und Getränke zu bestellen. Leider sind die Speisewagen oftmals überfüllt oder sogar ausgebucht. Deshalb wollte Elvetino, die Tochtergesellschaft der SBB AG, prüfen, ob und wie Sie zukünftig ihr Angebot erweitern kann. In einer Problem- und Lösungsexploration wurden mehrere Varianten analysiert, verglichen und evaluiert. Schlussendlich wurde entschieden, mit einem Pilotversuch eine Bestellapp zu testen, in welcher die Kunden direkt von ihren Sitzplätzen aus Speisen und Getränke bestellen können. Die Stewards im Zug bereiten die Bestellungen zu und liefern diese an die Sitzplätze der Kunden.
          </p>
          <p>&nbsp;</p>
          <p>
            Ich durfte das Projekt in der Rolle als Lead-UX unterstützen. Dabei konnte ich das Projektteam in allen Phasen des Innovationsprojektes, sowohl für die Bestellapp, wie auch für die Stewardapp in Konzeption, Gestaltung bis und mit der Umsetzung begleiten.
          </p>
        </template>
      </PortfolioCardR>

      <PortfolioCardL :imageURL="'midlands.jpg'" :fromBgGradientColor="'yellow-200'" :viaBgGradientColor="'yellow-600'" :toBgGradientColor="'yellow-200'" class="mt-24 3xl:mt-32">
        <template v-slot:cardTitle>BAND WEBSITE VON MIDLANDS</template>
        <template v-slot:cardDescription>
          <p>
            Als Mitglied der Rockband Midlands habe ich gerne die Erstellung und Pflege der Website übernommen. Wie bereits bei den anderen Band Websiten, welche ich erstellen durfte, war es mir auch hier wichtig, einen möglichst schlanken und übersichtlichen Webauftritt anzubieten. Diesmal habe ich mich zusätzlich mit Themen wie Parallax Effekt und Scroll Animationen auseinander gesetzt und wollte diese in die Seite integrieren, um das Erlebnis des Besuchers noch interessanter zu gestalten.
          </p>
          <p>&nbsp;</p>
          <p>
            Die Website war ideal, um das Webframework Svelte auszuprobieren und Neues zu lernen. Ich konnte die Band nicht nur mit der Website unterstützen sondern bin auch verantwortlich für Druckerzeugnisse für Veranstalter und allgemein anfallende Medienanliegen.
          </p>
        </template>
      </PortfolioCardL>

      <PortfolioCardR :imageURL="'inno_portfolio.jpg'" :fromBgGradientColor="'purple-300'" :viaBgGradientColor="'pink-800'" :toBgGradientColor="'indigo-400'" class="mt-24 3xl:mt-32">
        <template v-slot:cardTitle>INNOVATIONSMANAGEMENT DER SBB</template>
        <template v-slot:cardDescription>
          <p>
            Die SBB AG hat verschiedene Innovationsabteilungen und viele innovative Vorhaben. Als grosses Unternehmen ist es wichtig, einen Überblick zu haben, was wann wo genau läuft, um Synergien zu nutzen und Erkenntnisse festzuhalten. Das Innovationsmanagement Tool ist eine Webapplikation, in welcher die Innovationsverantwortlichen eines Projektes all dies tun können. Sie können die Innovationsprojekte erfassen, Fortschritte und Erkenntnisse festhalten und den Innovationsprozess der SBB geführt durchlaufen und dabei auch Neues zu Methodiken und Vorgehen im Bereich Innovation lernen.
          </p>
          <p>Ebenso gibt das Tool allen SBB Angestellen die Möglichkeit zu sehen, welche unterschiedlichen Innovationsthemen innerhalb der Firma am Laufen sind. Dazu könnnen die Benutzer nach Projekten suchen und filtern, sich Reports zu den Projekten anzeigen lassen, wie auch einen Überblick über ein einzelnes Vorhaben gewinnen. Diese Informationen können dann innerhalb des Unternehemens geteilt werden und helfen so, einen weiteren grossen Schritt in Richtung Digitalisierung zu gehen.</p>
          <p>&nbsp;</p>
          <p>
            Ich durfte das Projekt in der Rolle als Lead-UX unterstützen. Dabei konnte ich das Projektteam in allen Phasen des Innovationsprojektes in Konzeption, Gestaltung bis und mit der Umsetzung begleiten.
          </p>
        </template>
      </PortfolioCardR>

      <PortfolioCardL :imageURL="'setlist_generator_portfolio_medium.jpg'" :fromBgGradientColor="'blue-100'" :viaBgGradientColor="'blue-900'" :toBgGradientColor="'blue-400'" class="mt-24 3xl:mt-32">
        <template v-slot:cardTitle>SETLIST-GENERATOR</template>
        <template v-slot:cardDescription>
          <p>"Songs erfassen, Reihenfolge bestimmen, Setliste generieren lassen..."</p>
          <p>&nbsp;</p>
          <p>
            Als Musiker kennt man das Thema. Vor jedem Auftritt muss eine Setliste her. Der Setlist-Generator hilft diese Aufgabe schnell und unkompliziert zu lösen. Mit der responsive Web-Applikation kann die Band im Proberaum oder auch jeder für sich Zuhause auf die erfassten Songs der Band zugreifen und im Handumdrehen eine Setliste erstellen und nach Belieben anpassen. Dabei hilft der Setlist-Generator der Band bei der Planung einzelner Sets und Pausen. Zudem können Notizen zu jedem einzelnen Song hinterlegt, die Reihenfolge beliebig verändert und schliesslich ein PDF generiert werden.
          </p>
          <p>&nbsp;</p>
          <p>
            Als Mitglied in diversen Bands kenne ich das Thema sehr gut und wollte meine UX-, Design- und Entwickler-Skills nutzen, um den Bands diese Aufgabe abzunehmen und Freude in die Erstellung der Setlisten zu bringen.
          </p>
        </template>
      </PortfolioCardL>

      <PortfolioCardR :imageURL="'casa_portfolio_image_medium.jpg'" :fromBgGradientColor="'blue-400'" :viaBgGradientColor="'green-900'" :toBgGradientColor="'green-400'" class="mt-24 3xl:mt-32">
        <template v-slot:cardTitle>Redesign der Bahnschalter Verkaufsapplikation</template>
        <template v-slot:cardDescription>
          <p>Die Verkaufsapplikation am bedienten Bahnhofschalter von schweizer Transportunternehmen war bisher für einen schnellen Verkauf ausgerichtet. Die Bedienung der Kunden am Bahnschalter soll sich in Zukunft jedoch immer mehr in ein Beratungsgespräch umwandeln. Deshalb sollen die Bedienabläufe der Verkaufsapplikation (CASA, Customer and Sales Application), überarbeitet und einem Redesign unterzogen werden.
            So kann der Kundenberater neu, anhand eines umfassenden Kundendashboards, dem Kunden schneller Auskunft über seine Abonnemente, Rechnungen, gekauften Leistungen sowie Kundenstammdaten geben. Der Beratungs- und Verkaufsprozess für das Ticketing und die Produkte wurde komplett überarbeitet und optimiert. Das neue Bedienkonzept soll dem Kundenberater helfen, den Fokus auf die jeweiligen Prozessschritte zu legen und trotzdem anhand des Expertensystems dem Kunden detaillierte Auskünfte geben zu können.</p>
          <p>&nbsp;</p>
          <p>In diesem Projekt durfte ich die Lead-UX Rolle übernehmen und für das Haupt- sowie die unzähligen Teilprodukte alle UX-Disziplinen ausüben.</p>
        </template>
      </PortfolioCardR>

      <PortfolioCardL :imageURL="'beleven_portfolio_image_full.jpg'" :fromBgGradientColor="'red-300'" :viaBgGradientColor="'indigo-900'" :toBgGradientColor="'indigo-400'" class="mt-24 3xl:mt-32">
        <template v-slot:cardTitle>Band Website von BELEVEN</template>
        <template v-slot:cardDescription>
          <p>
            Als Mitglied dieser Acoustic Band Beleven, lag es mir am Herzen einen guten Webauftritt zu präsentieren. Die Website soll schlank daherkommen und den möglichen Interessenten, Veranstaltern sowie Fans einen Überblick über das Bandleben geben.
            Es gibt Kostproben von Demosongs, es wird über die nächsten Auftritte informiert und es besteht die Möglichkeit Kontakt aufzunehmen. Ebenso erfährt man etwas über die Bandmitglieder sowie für was die Band steht.
          </p>
          <p>&nbsp;</p>
          <p>
            Diese Website konnte ich mit meinen Skills konzeptionieren, designen sowie selber entwickeln und meine Fähigkeiten ausbauen.
            Die Grösse der Website ist ideal, um neue Web-Frameworks kennenzulernen und zu testen. Deshalb wurde die Website auch schon mehrmals neu mit verschiedenen Frameworks gebaut.
          </p>
        </template>
      </PortfolioCardL>

      <PortfolioCardR :imageURL="'crumb_portfolio_image_full_2.jpg'" :fromBgGradientColor="'gray-100'" :viaBgGradientColor="'gray-900'" :toBgGradientColor="'gray-500'" class="mt-24 3xl:mt-32 min-h-5">
        <template v-slot:cardTitle>Band Website von CRUMB</template>
        <template v-slot:cardDescription>
          <p>
            Die Rock- und Bluesband CRUMB hat mich gebeten ihren Webauftritt zu konzeptionieren, gestalten sowie umzusetzen.
            Ihr war wichtig, dass sie die Website später selber mit Inhalten erweitern und befüllen kann.</p>
          <p>&nbsp;</p>
          <p>Um diesen Anforderungen gerecht zu werden, braucht es ein CMS (Content Management System). Da ich bis dahin noch nicht mit Wordpress gearbeitet hatte und die Umsetzung realtiv schnell durchgeführt werden sollte, fand ich dieses kleine Projekt ideal, um dieses CMS besser kennen zu lernen.
          </p>
          <p>&nbsp;</p>
          <p>
            Mit diesem Projekt konnte ich Erfahrungen mit Worpress sammeln und meine UX-, Design- und Entwickler-Skills anwenden sowie erweitern.
          </p>
        </template>
      </PortfolioCardR>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PortfolioCardR from '@/components/PortfolioCardR.vue'
import PortfolioCardL from '@/components/PortfolioCardL.vue'

export default defineComponent({
  name: 'Portfolio',
  components: {
    PortfolioCardR,
    PortfolioCardL
  },
  setup() {
    return {}
  }
});
</script>

<style scoped>
.before-enter {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
}

.fadeInUp {
  animation-name: fadeInUp;
}

.fadeInUp1s {
  animation-name: fadeInUp;
  animation-delay: 0.5s;
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  99% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
}
</style>
