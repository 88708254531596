<template>
  <section id="hero">
    <!-- <div class="static z-50">
      <div class="absolute -top-2/3 h-full w-1/2 3xl:w-5/12 4xl:w-1/3 rounded-full opacity-70 filter blur-3xl left-0 3xl:left-48 4xl:left-96 bg-gradient-to-br from-dfink-petrol-default to-dfink-petrol-dark origin-top-left animate-heroBG animation-delay-1000"></div>
      <div class="absolute -top-1/2 h-full w-1/2 3xl:w-5/12 4xl:w-5/12 rounded-full opacity-70 filter blur-3xl right-1/3 3xl:right-1/3 4xl:right-1/3 bg-gradient-to-r from-dfink-petrol-dark to-dfink-blue origin-center animate-heroBG animation-delay-3000"></div>
      <div class="absolute -top-1/2 h-full w-1/2 3xl:w-5/12 4xl:w-5/12 rounded-full opacity-70 filter blur-3xl left-1/3 3xl:left-1/3 4xl:left-1/3 bg-gradient-to-r from-dfink-blue to-dfink-violet origin-center animate-heroBG animation-delay-6000"></div>
      <div class="absolute -top-2/3 h-full w-1/2 3xl:w-5/12 4xl:w-1/3 rounded-full opacity-70 filter blur-3xl right-0 3xl:right-48 4xl:right-96 bg-gradient-to-tr from-dfink-violet to-dfink-petrol-dark origin-top-right animate-heroBG animation-delay-9000"></div>
    </div> -->

    <div class="static z-50">
      <div class="absolute -top-2/3 h-full w-1/2 3xl:w-5/12 4xl:w-1/3 rounded-full opacity-70 filter blur-3xl left-0 3xl:left-48 4xl:left-96 bg-gradient-to-br from-green-700 via-green-900 to-yellow-900 origin-top-left animate-heroBG animation-delay-1000"></div>
      <div class="absolute -top-1/2 h-full w-1/2 3xl:w-5/12 4xl:w-5/12 rounded-full opacity-70 filter blur-3xl right-1/3 3xl:right-1/3 4xl:right-1/3 bg-gradient-to-r from-green-700 via-blue-700 to-blue-800 origin-center animate-heroBG animation-delay-3000"></div>
      <div class="absolute -top-2/3 h-full w-1/2 3xl:w-5/12 4xl:w-1/3 rounded-full opacity-70 filter blur-3xl right-0 3xl:right-48 4xl:right-96 bg-gradient-to-tr from-blue-900 via-purple-600 to-yellow-700 origin-top-right animate-heroBG animation-delay-9000"></div>
      <div class="absolute -top-1/2 h-full w-1/2 3xl:w-5/12 4xl:w-5/12 rounded-full opacity-70 filter blur-3xl left-1/3 3xl:left-1/3 4xl:left-1/3 bg-gradient-to-r from-blue-900 via-indigo-600 to-indigo-800 origin-center animate-heroBG animation-delay-6000"></div>
      <div class="absolute -top-2/3 h-full w-1/2 3xl:w-5/12 4xl:w-1/3 rounded-full opacity-70 filter blur-3xl right-0 3xl:right-48 4xl:right-96 bg-gradient-to-tr from-blue-600 to-red-700 origin-top-right animate-heroBG animation-delay-6000"></div>
    </div>

    <div class="relative min-h-screen max-w-7xl px-4 sm:px-6 mx-auto">
      <div class="relative h-screen max-w-7xl px-4 sm:px-6 mx-auto flex flex-col-reverse items-center sm:flex-row sm:items-center sm:justify-center">
        <img class="w-2/3 sm:w-1/3 md:w-1/2 3xl:w-5/6 mb-6 md:mt-16 opacity-0" src="../../assets/images/profile_image.png" v-scrollanimation="{enter: 'fadeInUp', once: true}" />
        <div class=" flex flex-col mb-16 sm:mb-0">
          <h1 class="text-dfink-white text-center md:text-left text-3xl md:text-5xl 3xl:text-6xl font-black md:pt-36 opacity-0" v-scrollanimation="{enter: 'fadeInDown', leave: 'fadeOutUp', once: true}">HI, ICH BIN DANIEL</h1>
          <h3 class="text-dfink-white text-center md:text-left text-lg md:text-xl 3xl:text-2xl font-normal pt-4 opacity-0" v-scrollanimation="{enter: 'fadeInRight', leave: 'fadeOutRight', once: true}">User Experience Architect, innovativ, kreativ, kommunikativ und immer bereit, etwas Neues zu lernen und auszuprobieren</h3>
          <div class="hidden md:flex justify-start pt-20 pl-16" v-scrollanimation="{beforeEnter: 'button-before-enter', enter: 'fadeInRight', leave: 'fadeOutRight', once: true}">
            <Button id="button" :isNavigation="true" :url="'#about'" :text="'Erfahren Sie mehr'" class="button-pulse"></Button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Button from '@/components/Button.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'Hero',
  components: {
    Button,
  },
  setup() {
    return
  }
});
</script>

<style scoped>
.button-before-enter {
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: both;
}

.button-pulse {
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

</style>
