<template>
  <div class="grid grid-flow-col auto-cols-auto">
    <span class="text-dfink-white text-base 2xl:text-lg" :class="{'text-center' : maxPoints == 0}" v-scrollanimation="{enter: 'fadeIn', once: true}">{{ text }}</span>

    <div class="flex justify-end pl-2" v-if="maxPoints != 0">
      <div class="flex flex-row relative">
        <div>
          <div v-scrollanimation="{enter: 'star1-enter', leave: 'star1-leave', once: true}">
            <svg :class="skillPoints >= 1 ? 'opacity-100': 'opacity-0'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.0005 0L14.6947 8.2918H23.4132L16.3598 13.4164L19.054 21.7082L12.0005 16.5836L4.94713 21.7082L7.64129 13.4164L0.587872 8.2918H9.30638L12.0005 0Z" fill="white" />
            </svg>
          </div>
          <div class="-mt-6" v-scrollanimation="{enter: 'fadeIn', once: true}">
            <svg class="opacity-10" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.0005 0L14.6947 8.2918H23.4132L16.3598 13.4164L19.054 21.7082L12.0005 16.5836L4.94713 21.7082L7.64129 13.4164L0.587872 8.2918H9.30638L12.0005 0Z" fill="white" />
            </svg>
          </div>
        </div>

        <div>
          <div v-scrollanimation="{enter: 'star2-enter', leave: 'star2-leave', once: true}">
            <svg :class="skillPoints >= 2 ? 'opacity-100': 'opacity-0'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.0005 0L14.6947 8.2918H23.4132L16.3598 13.4164L19.054 21.7082L12.0005 16.5836L4.94713 21.7082L7.64129 13.4164L0.587872 8.2918H9.30638L12.0005 0Z" fill="white" />
            </svg>
          </div>
          <div class="-mt-6" v-scrollanimation="{enter: 'fadeIn', once: true}">
            <svg class="opacity-10" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.0005 0L14.6947 8.2918H23.4132L16.3598 13.4164L19.054 21.7082L12.0005 16.5836L4.94713 21.7082L7.64129 13.4164L0.587872 8.2918H9.30638L12.0005 0Z" fill="white" />
            </svg>
          </div>
        </div>

        <div>
          <div v-scrollanimation="{enter: 'star3-enter', leave: 'star3-leave', once: true}">
            <svg :class="skillPoints >= 3 ? 'opacity-100': 'opacity-0'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.0005 0L14.6947 8.2918H23.4132L16.3598 13.4164L19.054 21.7082L12.0005 16.5836L4.94713 21.7082L7.64129 13.4164L0.587872 8.2918H9.30638L12.0005 0Z" fill="white" />
            </svg>
          </div>
          <div class="-mt-6" v-scrollanimation="{enter: 'fadeIn', once: true}">
            <svg class="opacity-10" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.0005 0L14.6947 8.2918H23.4132L16.3598 13.4164L19.054 21.7082L12.0005 16.5836L4.94713 21.7082L7.64129 13.4164L0.587872 8.2918H9.30638L12.0005 0Z" fill="white" />
            </svg>
          </div>
        </div>

        <div>
          <div v-scrollanimation="{enter: 'star4-enter', leave: 'star4-leave', once: true}">
            <svg :class="skillPoints >= 4 ? 'opacity-100': 'opacity-0'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.0005 0L14.6947 8.2918H23.4132L16.3598 13.4164L19.054 21.7082L12.0005 16.5836L4.94713 21.7082L7.64129 13.4164L0.587872 8.2918H9.30638L12.0005 0Z" fill="white" />
            </svg>
          </div>
          <div class="-mt-6" v-scrollanimation="{enter: 'fadeIn', once: true}">
            <svg class="opacity-10" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.0005 0L14.6947 8.2918H23.4132L16.3598 13.4164L19.054 21.7082L12.0005 16.5836L4.94713 21.7082L7.64129 13.4164L0.587872 8.2918H9.30638L12.0005 0Z" fill="white" />
            </svg>
          </div>
        </div>

        <div>
          <div v-scrollanimation="{enter: 'star5-enter', leave: 'star5-leave', once: true}">
            <svg :class="skillPoints >= 5 ? 'opacity-100': 'opacity-0'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.0005 0L14.6947 8.2918H23.4132L16.3598 13.4164L19.054 21.7082L12.0005 16.5836L4.94713 21.7082L7.64129 13.4164L0.587872 8.2918H9.30638L12.0005 0Z" fill="white" />
            </svg>
          </div>
          <div class="-mt-6" v-scrollanimation="{enter: 'fadeIn', once: true}">
            <svg class="opacity-10" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.0005 0L14.6947 8.2918H23.4132L16.3598 13.4164L19.054 21.7082L12.0005 16.5836L4.94713 21.7082L7.64129 13.4164L0.587872 8.2918H9.30638L12.0005 0Z" fill="white" />
            </svg>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Skill',
  props: {
    text: {
      type: String,
      required: true
    },
    skillPoints: {
      type: Number,
      required: true
    },
    maxPoints: {
      type: Number,
      required: true
    }
  },
  setup() {
    return {
    }
  }
});
</script>

<style scoped>
.before-enter {
  transition: all 0.7s;
}

.enter {
  opacity: 1;
}

.leave {
  opacity: 0;
}

.star1-enter {
  opacity: 1;
  transition-delay: 300ms;
}

.star2-enter {
  opacity: 1;
  transition-delay: 450ms;
}

.star3-enter {
  opacity: 1;
  transition-delay: 600ms;
}

.star4-enter {
  opacity: 1;
  transition-delay: 750ms;
}

.star5-enter {
  opacity: 1;
  transition-delay: 900ms;
}

.star1-leave, .star2-leave, .star3-leave, .star4-leave, .star5-leave {
  opacity: 0;
  transition-delay: 0ms;
}
</style>
