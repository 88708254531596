
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Button',
  props: {
    text: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    isNavigation: {
      type: Boolean,
      required: true
    },
  },
  setup(props) {

    function clickEvent() {
      window.open(props.url, '_blank');
    }

    return {
      clickEvent
    }
  }
});
