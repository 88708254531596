<template>
  <section id="contact" class="relative mt-24">
    <!-- <div class="absolute h-full w-full overflow-hidden">
      <div class="absolute -bottom-2/3 h-full w-1/2 3xl:w-5/12 4xl:w-1/3 rounded-full opacity-70 filter blur-3xl left-0 3xl:left-48 4xl:left-96 bg-gradient-to-br from-dfink-petrol-default to-dfink-petrol-dark origin-bottom-left animate-heroBG animation-delay-1000"></div>
      <div class="absolute -bottom-1/2 h-full w-1/2 3xl:w-5/12 4xl:w-5/12 rounded-full opacity-70 filter blur-3xl right-1/3 3xl:right-1/3 4xl:right-1/3 bg-gradient-to-r from-dfink-petrol-dark to-dfink-blue origin-center animate-heroBG animation-delay-3000"></div>
      <div class="absolute -bottom-1/2 h-full w-1/2 3xl:w-5/12 4xl:w-5/12 rounded-full opacity-70 filter blur-3xl left-1/3 3xl:left-1/3 4xl:left-1/3 bg-gradient-to-r from-dfink-blue to-dfink-violet origin-center animate-heroBG animation-delay-6000"></div>
      <div class="absolute -bottom-2/3 h-full w-1/2 3xl:w-5/12 4xl:w-1/3 rounded-full opacity-70 filter blur-3xl right-0 3xl:right-48 4xl:right-96 bg-gradient-to-tr from-dfink-violet to-dfink-petrol-dark origin-bottom-right animate-heroBG animation-delay-9000"></div>
    </div> -->

    <div class="absolute h-full w-full overflow-hidden">
      <div class="absolute -bottom-2/3 h-full w-1/2 3xl:w-5/12 4xl:w-1/3 rounded-full opacity-70 filter blur-3xl left-0 3xl:left-48 4xl:left-96 bg-gradient-to-br from-green-700 via-green-900 to-yellow-900 origin-bottom-left animate-heroBG animation-delay-1000"></div>
      <div class="absolute -bottom-1/2 h-full w-1/2 3xl:w-5/12 4xl:w-5/12 rounded-full opacity-70 filter blur-3xl right-1/3 3xl:right-1/3 4xl:right-1/3 bg-gradient-to-r from-green-700 via-blue-700 to-blue-800 origin-center animate-heroBG animation-delay-3000"></div>
      <div class="absolute -bottom-1/2 h-full w-1/2 3xl:w-5/12 4xl:w-5/12 rounded-full opacity-70 filter blur-3xl left-1/3 3xl:left-1/3 4xl:left-1/3 bg-gradient-to-r from-blue-900 via-indigo-600 to-indigo-800 origin-center animate-heroBG animation-delay-6000"></div>
      <div class="absolute -bottom-2/3 h-full w-1/2 3xl:w-5/12 4xl:w-1/3 rounded-full opacity-70 filter blur-3xl right-0 3xl:right-48 4xl:right-96 bg-gradient-to-tr from-blue-900 via-purple-600 to-yellow-700 origin-bottom-right animate-heroBG animation-delay-9000"></div>
    </div>

    <div class="relative min-h-screen max-w-7xl mx-auto px-4 sm:px-6 flex flex-col items-center justify-center">
      <p class="z-0 text-dfink-white text-lg md:text-xl 3xl:text-2xl mt-16 3xl:mt-24 text-center" v-scrollanimation="{enter: 'fadeInLeft', leave: 'fadeOutRight', once: true}">Habe ich Ihr Interesse geweckt und Sie möchten mich kontaktieren?</p>

      <div v-scrollanimation="{enter: 'fadeInRight', leave: 'fadeOutLeft', once: true}">
        <Button :isNavigation="false" :url="'mailto:daniel.fink@gmx.ch'" :text="'Kontakt aufnehmen'" class="z-0 mt-12 button-pulse"></Button>
      </div>
      <p class="z-0 absolute text-dfink-white text-xs text-center bottom-0 mb-24 sm:mb-4 md:mb-24">Copyright &copy; {{ copyrightYear }} Daniel Fink</p>
    </div>

  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'Contact',
  components: {
    Button
  },
  setup() {
    const copyrightYear = (new Date()).getFullYear();

    return {
      copyrightYear
    }
  }
});
</script>

<style scoped>
.button-pulse {
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
</style>
