
import { defineComponent } from 'vue';
import Button from '@/components/Button.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'Hero',
  components: {
    Button,
  },
  setup() {
    return
  }
});
