
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Skill',
  props: {
    text: {
      type: String,
      required: true
    },
    skillPoints: {
      type: Number,
      required: true
    },
    maxPoints: {
      type: Number,
      required: true
    }
  },
  setup() {
    return {
    }
  }
});
