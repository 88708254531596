
import { defineComponent } from 'vue';
import Navbar from '@/components/Navbar.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'Home',
  components: {
    Navbar
  },
});
