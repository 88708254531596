import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/styles/tailwind.css'
import './assets/styles/animations.css'
import ScrollAnimation from '@/directives/scrollanimation'
import VueSmoothScroll from 'vue3-smooth-scroll'


createApp(App)
  .directive('scrollanimation', ScrollAnimation)
  .use(router)
  .use(VueSmoothScroll, {
    duration: 2000,
  })
  .mount('#app')

