
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Navbar',

  setup() {
    const showMobileMenu = ref(false);

    function toggleMobileMenu() {
      showMobileMenu.value = !showMobileMenu.value;
    }

    return {
      showMobileMenu, toggleMobileMenu
    }
  }
});
