
import { computed, defineComponent, onMounted, ref } from 'vue';
import VanillaTilt from 'vanilla-tilt';

export default defineComponent({
  name: 'PortfolioCard',
  props: {
    imageURL: {
      type: String,
      required: true
    },
    fromBgGradientColor: {
      type: String,
      required: false
    },
    viaBgGradientColor: {
      type: String,
      required: false
    },
    toBgGradientColor: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const cardRef = ref();
    const bgGradient = computed(() => {
      return "from-" + props.fromBgGradientColor + " via-" + props.viaBgGradientColor + " to-" + props.toBgGradientColor;
    });

    function is_touch_enabled() {
      return ('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0);
    }

    onMounted(() => {
      if (!is_touch_enabled()) {
        VanillaTilt.init(cardRef.value, {
          reverse: true,
          max: 10,
          glare: true,
          reset: true,
          "max-glare": 0.5
        });
      }
    })

    return {
      cardRef, bgGradient
    }
  }
});
