
import { defineComponent } from 'vue';
import Hero from '@/components/sections/Hero.vue'; // @ is an alias to /src
import About from '@/components/sections/About.vue'; // @ is an alias to /src
import Portfolio from '@/components/sections/Portfolio.vue'; // @ is an alias to /src
import Contact from '@/components/sections/Contact.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'Home',
  components: {
    Hero,
    About,
    Portfolio,
    Contact
  },
});
